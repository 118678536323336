import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CookieConsentBase from 'react-cookie-consent';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

const CookieConsentBanner = () => {
  const { t } = useTranslation();
  const [showPreferences, setShowPreferences] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true, // Always true and disabled
    analytics: true,
    marketing: false,
  });

  const handleAcceptAll = () => {
    setCookiePreferences({
      necessary: true,
      analytics: true,
      marketing: true,
    });
    // Save preferences to cookies
    document.cookie = "cookiePreferences=" + JSON.stringify({
      necessary: true,
      analytics: true,
      marketing: true,
    }) + ";path=/;max-age=31536000"; // 1 year
  };

  const handleSavePreferences = () => {
    // Save preferences to cookies
    document.cookie = "cookiePreferences=" + JSON.stringify(cookiePreferences) + ";path=/;max-age=31536000";
    setShowPreferences(false);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  return (
    <>
      <CookieConsentBase
        location="bottom"
        buttonText={t('cookieConsent.accept')}
        declineButtonText={t('cookieConsent.decline')}
        cookieName="auralid-cookie-consent"
        style={{
          background: 'rgba(43, 55, 59, 0.95)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 -4px 10px rgba(0, 0, 0, 0.1)',
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: '15px'
        }}
        buttonStyle={{
          background: '#D3C033',
          color: '#2B373B',
          fontSize: '14px',
          padding: '10px 25px',
          borderRadius: '25px',
          fontWeight: '600',
          border: 'none',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            background: '#E0CD40'
          }
        }}
        declineButtonStyle={{
          background: 'transparent',
          border: '2px solid #D3C033',
          color: '#D3C033',
          fontSize: '14px',
          padding: '8px 23px',
          borderRadius: '25px',
          fontWeight: '600',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            background: 'rgba(211, 192, 51, 0.1)'
          }
        }}
        expires={150}
        enableDeclineButton
        onAccept={handleAcceptAll}
      >
        <div style={{ maxWidth: '800px' }}>
          <h3 style={{ color: '#D3C033', marginBottom: '10px', fontSize: '18px' }}>
            {t('cookieConsent.message')}
          </h3>
          <p style={{ color: '#fff', fontSize: '14px', lineHeight: '1.5' }}>
            {t('cookieConsent.description')}
          </p>
          <button
            onClick={() => setShowPreferences(true)}
            style={{
              background: 'transparent',
              border: 'none',
              color: '#D3C033',
              textDecoration: 'underline',
              cursor: 'pointer',
              padding: '5px 0',
              fontSize: '14px',
              marginTop: '10px'
            }}
          >
            {t('cookieConsent.customize')}
          </button>
        </div>
      </CookieConsentBase>

      <Modal
        open={showPreferences}
        onClose={() => setShowPreferences(false)}
        aria-labelledby="cookie-preferences-modal"
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" sx={{ mb: 2, color: '#2B373B' }}>
            {t('cookieConsent.preferences')}
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={cookiePreferences.necessary}
                  disabled
                  color="primary"
                />
              }
              label={
                <div>
                  <Typography variant="subtitle1">{t('cookieConsent.necessary.title')}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('cookieConsent.necessary.description')}
                  </Typography>
                </div>
              }
            />
            <FormControlLabel
              control={
                <Switch
                  checked={cookiePreferences.analytics}
                  onChange={(e) => setCookiePreferences({
                    ...cookiePreferences,
                    analytics: e.target.checked
                  })}
                  color="primary"
                />
              }
              label={
                <div>
                  <Typography variant="subtitle1">{t('cookieConsent.analytics.title')}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('cookieConsent.analytics.description')}
                  </Typography>
                </div>
              }
            />
            <FormControlLabel
              control={
                <Switch
                  checked={cookiePreferences.marketing}
                  onChange={(e) => setCookiePreferences({
                    ...cookiePreferences,
                    marketing: e.target.checked
                  })}
                  color="primary"
                />
              }
              label={
                <div>
                  <Typography variant="subtitle1">{t('cookieConsent.marketing.title')}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('cookieConsent.marketing.description')}
                  </Typography>
                </div>
              }
            />
          </FormGroup>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
            <Link
              to="/privacy-policy"
              style={{
                color: '#2B373B',
                textDecoration: 'none',
                fontSize: '14px',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {t('cookieConsent.learnMore')}
            </Link>
            <Box>
              <Button
                onClick={() => setShowPreferences(false)}
                sx={{
                  mr: 2,
                  color: '#2B373B',
                  borderColor: '#2B373B',
                  '&:hover': {
                    borderColor: '#2B373B',
                    background: 'rgba(43, 55, 59, 0.1)'
                  }
                }}
                variant="outlined"
              >
                {t('cookieConsent.decline')}
              </Button>
              <Button
                onClick={handleSavePreferences}
                sx={{
                  background: '#D3C033',
                  color: '#2B373B',
                  '&:hover': {
                    background: '#E0CD40'
                  }
                }}
                variant="contained"
              >
                {t('cookieConsent.accept')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CookieConsentBanner; 