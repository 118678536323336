// Google Analytics tracking utility
export const gtag = (...args) => {
    if (typeof window !== 'undefined' && window.gtag) {
        window.gtag(...args);
    }
};

// Initialize Google Analytics
export const initGA = (trackingId) => {
    if (typeof window === 'undefined') return;
    
    // Load the Google Analytics script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
        window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', trackingId);
}; 